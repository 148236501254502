<!--重发收款按钮-->
<template>
  <div class="btn">
    <el-button type="text" size="medium" @click.stop="goSend()"
      >重发收款</el-button
    >
  </div>
</template>
  <script>
import { goResurrecting } from "@/api/party/party.js";
export default {
  props: {
    childRow: {
      type: Object,
      default: null,
    },
  },
  methods: {
    // 重发收款
    goResurrecting() {
      let params = {
        groupOrderId: this.childRow.groupOrderId,
      };
      goResurrecting(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.$emit("onSuccess");
        }
      });
    },
    goSend() {
      this.$confirm("请确定是否重发收款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.goResurrecting();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>
    <style lang="scss" scoped>
.btn {
  margin-right: 10px;
}
</style>