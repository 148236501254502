<!--团建管理-->
<template>
  <div id="party">
    <div class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="请输入手机号/团建公司"
          @input="kwChange"
          clearable
          class="mr15"
        />
        <el-select
          v-model="tableParams.groupStatus"
          placeholder="请选择团建状态"
          clearable
          @change="reload"
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(partyStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="search-R">
        <CreatePartyBtn :type="1" :roomList="roomList" @onSuccess="onSuccess" />
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            :data="props.row.groupOrderVoList"
            border
            stripe
            :header-cell-style="{
              background: '#FAFAFA',
              color: '#000000',
            }"
          >
            <el-table-column
              label="团单时间"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <span>{{ child.row.addDt }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="下单用户"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <span>{{ child.row.mobile }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="包厢位置"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <div
                  v-if="
                    child.row.groupRoomOrderVoList &&
                    child.row.groupRoomOrderVoList.length
                  "
                >
                  <div
                    v-for="item in child.row.groupRoomOrderVoList"
                    :key="item.id"
                  >
                    {{ item.roomName }}({{ item.startTime }}至{{
                      item.endTime
                    }})
                  </div>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              label="其他服务"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <div
                  v-if="
                    child.row.otherServiceVoList &&
                    child.row.otherServiceVoList.length
                  "
                >
                  <div
                    v-for="item in child.row.otherServiceVoList"
                    :key="item.id"
                  >
                    {{ item.serviceName }}
                  </div>
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              label="团单状态"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <span>{{ partyOrderStatusObj[child.row.orderStatus] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="付款方式"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <span>{{ child.row.paymentTypeName || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="使用优惠券"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <span>{{ child.row.cardName || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="折扣优惠"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <span>{{
                  (child.row.orderPrice - child.row.discountPrice).toFixed(2)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="商家补贴"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <span>{{ child.row.subsidyAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="实价/折扣价/原价"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="child">
                <span
                  >{{ child.row.payableAmount }}/{{
                    child.row.discountPrice
                  }}/{{ child.row.orderPrice }}</span
                >
              </template>
            </el-table-column>
            <el-table-column width="200" label="操作" align="center">
              <template slot-scope="child">
                <div style="width: 200px; display: flex; flex-wrap: wrap">
                  <CreatePartyBtn
                    v-if="
                      child.row.orderStatus == 1 || child.row.orderStatus == 10
                    "
                    :type="3"
                    :row="props.row"
                    :childRow="child.row"
                    :roomList="roomList"
                    @onSuccess="onSuccess"
                  />
                  <CancelOrderBtn
                    v-if="
                      child.row.orderStatus == 1 || child.row.orderStatus == 10
                    "
                    :childRow="child.row"
                    @onSuccess="onSuccess"
                  />
                  <RefundBtn
                    :childRow="child.row"
                    v-if="child.row.orderStatus == 2"
                    @onSuccess="onSuccess"
                  />
                  <ReSendOrderBtn
                    v-if="child.row.orderStatus == 10"
                    :childRow="child.row"
                    @onSuccess="onSuccess"
                  />
                  <RecordListBtn :childRow="child.row" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="团建创建时间"
        align="center"
        show-overflow-tooltip
        prop="addDt"
      />

      <el-table-column
        label="团主"
        align="center"
        show-overflow-tooltip
        prop="mobile"
      />
      <el-table-column
        label="团建公司"
        align="center"
        show-overflow-tooltip
        prop="corporateName"
      />
      <el-table-column
        label="团建人数"
        align="center"
        show-overflow-tooltip
        prop="peopleNum"
      />

      <el-table-column label="团建时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.startTime }} ～ {{ scope.row.endTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="包厢折扣"
        align="center"
        show-overflow-tooltip
        prop="roomDiscount"
      />

      <el-table-column
        label="商城折扣"
        align="center"
        show-overflow-tooltip
        prop="mallDiscount"
      />

      <el-table-column label="订单数" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.groupOrderVoList.length }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="团建总实价/总原价"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            >¥{{ scope.row.totalPayableAmount }}/¥{{
              scope.row.totalOrderPrice
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="团建状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ partyStatusObj[scope.row.groupStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <div
            style="width: 200px; display: flex; flex-wrap: wrap"
            v-if="scope.row.groupStatus != 5"
          >
            <template v-if="scope.row.groupStatus != 4">
              <CreatePartyBtn
                :type="2"
                :row="scope.row"
                :roomList="roomList"
                @onSuccess="onSuccess"
              />
              <ChangeTimeBtn :row="scope.row" @onSuccess="onSuccess" />
              <BoxOrderBtn
                v-if="scope.row.groupStatus != 1"
                :row="scope.row"
                :roomList="roomList"
              />

              <SettleBtn
                :row="scope.row"
                v-if="scope.row.groupStatus == 3"
                @onSuccess="onSuccess"
              />
            </template>

            <DeleteBtn
              v-if="scope.row.groupStatus == 4"
              :row="scope.row"
              @onSuccess="onSuccess"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getPartyList } from "@/api/party/party.js";
import { getIdroomList } from "@/api/order/order";
import { partyStatusObj, partyOrderStatusObj } from "@/db/objs.js";
import { ObjToOpt } from "@/utils/utils";
import CreatePartyBtn from "./CreatePartyBtn.vue";
import ChangeTimeBtn from "./ChangeTimeBtn.vue";
import BoxOrderBtn from "./BoxOrderBtn.vue";
import DeleteBtn from "./DeleteBtn.vue";
import CancelOrderBtn from "./CancelOrderBtn.vue";
import ReSendOrderBtn from "./ReSendOrderBtn.vue";
import RecordListBtn from "./RecordListBtn.vue";
import RefundBtn from "./RefundBtn.vue";
import SettleBtn from "./SettleBtn.vue";
export default {
  components: {
    CreatePartyBtn,
    BoxOrderBtn,
    ChangeTimeBtn,
    DeleteBtn,
    CancelOrderBtn,
    ReSendOrderBtn,
    RecordListBtn,
    SettleBtn,
    RefundBtn,
  },

  data() {
    return {
      ObjToOpt,
      partyStatusObj,
      partyOrderStatusObj,
      currentPage: 1,
      tableData: [],
      total: 0,
      tableParams: {
        kw: "",
        page: 1,
        pageSize: 10,
        groupStatus: "",
        merchantId: "",
        shopId: "",
      },
      roomList: [],
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getIdroomList(); //【请求】位置列表
    this.getPartyList();
  },
  methods: {
    // 查询团建列表
    getPartyList() {
      getPartyList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 操作成功后刷新接口
    onSuccess() {
      this.getPartyList();
    },
    // 【请求】位置列表
    getIdroomList() {
      let merchantShopId = window.localStorage.getItem(
        "playOne-store-merchantShopId"
      );
      let data = {
        merchantId: JSON.parse(merchantShopId)[0],
        shopId: JSON.parse(merchantShopId)[1],
      };
      getIdroomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.roomList = res.data;
        }
      });
    },
    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.pageSize = size;
      this.reload();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPartyList();
    },
    // 表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getPartyList();
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),
  },
};
</script>
<style lang="scss" scoped>
#party {
  padding: 0.15rem;
  box-sizing: border-box;

  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    .search-L {
      display: flex;
    }
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>