<!--创建团单-->
<template>
  <div class="btn">
    <el-button v-if="type == 1" type="primary" @click.stop="goAdd()"
      >创建团建</el-button
    >
    <el-button v-if="type == 2" type="text" size="medium" @click.stop="goAdd()"
      >增加团单</el-button
    >
    <el-button v-if="type == 3" type="text" size="medium" @click.stop="goAdd()"
      >编辑团单</el-button
    >
    <el-dialog :title="title" :visible.sync="addVisible" width="950px">
      <el-form
        :model="params"
        :rules="rules"
        ref="form-ref"
        label-width="120px"
      >
        <template v-if="type == 1">
          <div class="title">团建信息</div>

          <div class="line">
            <el-form-item label="团主" prop="memberId">
              <el-select
                :disabled="type != 1"
                v-model="params.memberId"
                filterable
                clearable
                placeholder="搜索选择用户"
                remote
                :remote-method="remoteMethod"
                @change="getUserCard"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.nickName + ' | ' + item.mobile"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="团建时间" prop="timeToTime">
              <el-date-picker
                v-model="timeToTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="getUserCard"
              >
              </el-date-picker>
            </el-form-item>
          </div>

          <div class="line">
            <el-form-item label="团建人数" prop="peopleNum">
              <el-input
                v-model="params.peopleNum"
                type="number"
                oninput="value=value.replace(/\D|^0/g, '')"
                placeholder="请输入团建人数"
              ></el-input>
            </el-form-item>
            <el-form-item label="团建公司" prop="corporateName">
              <el-input
                v-model="params.corporateName"
                placeholder="请输入团建公司"
              ></el-input>
            </el-form-item>
          </div>
          <el-divider></el-divider>
        </template>
        <template>
          <div class="title">折扣信息</div>
          <div class="line">
            <el-form-item label="包厢折扣">
              <el-input
                :disabled="type != 1"
                v-model="params.roomDiscount"
                placeholder="请输入包厢折扣"
                oninput="value=value.replace(/\D|^0/g, '')"
                @input="goLimitValue"
                type="number"
                :max="10"
                :min="1"
                @change="goChangeRoomPrice"
              >
                <template slot="append">折</template>
              </el-input>
            </el-form-item>
            <el-form-item label="商城折扣">
              <el-input
                :disabled="type != 1"
                v-model="params.mallDiscount"
                placeholder="请输入商城折扣"
                oninput="value=value.replace(/\D|^0/g, '')"
                @input="goLimitValue"
                type="number"
                :max="10"
                :min="1"
              >
                <template slot="append">折</template>
              </el-input>
            </el-form-item>
          </div>

          <el-divider></el-divider>
        </template>
        <div class="list">
          <div
            class="box"
            v-if="
              params.groupOrderDTO.groupRoomOrderDTOList &&
              params.groupOrderDTO.groupRoomOrderDTOList.length
            "
          >
            <div
              class="item"
              v-for="(item, index) in params.groupOrderDTO
                .groupRoomOrderDTOList"
              :key="index"
            >
              <div class="item-line">
                <el-tooltip :content="item.roomName" placement="top">
                  <div class="txt">{{ item.roomName }}</div>
                </el-tooltip>
                <el-tooltip
                  :content="item.startTime + '~' + item.endTime"
                  placement="top"
                >
                  <div class="txt">{{ item.startTime }}~{{ item.endTime }}</div>
                </el-tooltip>

                <span class="txt">原价：¥{{ item.orderPrice }}</span>
                <span class="txt">折扣价：¥{{ item.discountPrice }}</span>
              </div>
              <span>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="small"
                  @click="goEditAddress(item, index)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="small"
                  @click="goDeleteAddress(index)"
                ></el-button>
              </span>
            </div>
          </div>
          <el-button type="primary" plain size="small" @click="goAddOther(1)"
            >添加位置</el-button
          >
        </div>
        <div class="list">
          <div
            class="box"
            v-if="
              params.groupOrderDTO.otherServiceDTOList &&
              params.groupOrderDTO.otherServiceDTOList.length
            "
          >
            <div
              class="item"
              v-for="(item, index) in params.groupOrderDTO.otherServiceDTOList"
              :key="index"
            >
              <div class="item-line">
                <el-tooltip :content="item.serviceName" placement="top">
                  <div class="txt">服务名：{{ item.serviceName }}</div>
                </el-tooltip>
                <span class="txt">服务原价：¥{{ item.orderPrice }}</span>
                <span class="txt">服务折扣价：¥{{ item.discountPrice }}</span>
              </div>
              <span>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  size="small"
                  @click="goEditService(item, index)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="small"
                  @click="goDeleteService(index)"
                ></el-button>
              </span>
            </div>
          </div>
          <el-button type="primary" plain size="small" @click="goAddOther(2)"
            >添加其他服务</el-button
          >
        </div>
        <el-divider></el-divider>
        <div class="line">
          <el-form-item label="选择团建券">
            <el-select
              v-model="params.groupOrderDTO.cardId"
              clearable
              placeholder="请选择团建卡券"
            >
              <el-option
                v-for="item in cardList"
                :key="item.memberCardId"
                :label="item.cardName"
                :value="item.memberCardId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商家补贴">
            <el-input
              v-model="params.groupOrderDTO.subsidyAmount"
              placeholder="请输入商家补贴"
              @input="formatNum"
              @change="calculateTotal()"
            >
              <template slot="append">元</template></el-input
            >
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label="原价总额">
            <el-input v-model="params.groupOrderDTO.orderPrice" disabled>
              <template slot="append">元</template></el-input
            >
          </el-form-item>
          <el-form-item label="折扣价总额">
            <el-input v-model="params.groupOrderDTO.discountPrice" disabled>
              <template slot="append">元</template></el-input
            >
          </el-form-item>
        </div>
        <div class="line">
          <el-form-item label="实价总额">
            <el-input v-model="params.groupOrderDTO.payableAmount" disabled>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="confirm">
        <el-button type="primary" @click="onSubmitParty('form-ref')">{{
          btnTitle
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑位置" :visible.sync="addAddressVisible" width="600px">
      <el-form
        :model="address"
        :rules="rules"
        ref="form-address"
        label-width="120px"
      >
        <!-- 起止时间 -->
        <el-form-item label="位置订单时间" prop="addressTime">
          <el-date-picker
            v-model="addressTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 位置名称 -->
        <el-form-item label="位置名称" prop="roomId">
          <el-select
            v-model="address.roomId"
            placeholder="请选择位置名称"
            @change="getAddressRoomName"
          >
            <el-option
              v-for="item in roomList"
              :key="item.roomId"
              :label="item.roomName"
              :value="item.roomId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="confirm">
        <el-button type="primary" @click="onSubmitAddress('form-address')"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="编辑服务"
      :visible.sync="otherServiceVisible"
      width="500px"
    >
      <el-form
        :model="service"
        :rules="rules"
        ref="form-service"
        label-width="120px"
      >
        <el-form-item label="服务名称" prop="serviceName">
          <el-input
            v-model="service.serviceName"
            placeholder="请输入服务名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="服务原价" prop="orderPrice">
          <el-input
            v-model="service.orderPrice"
            placeholder="请输入服务原价"
            type="number"
            oninput="value=value.replace(/\D|^0/g, '')"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务折扣价" prop="discountPrice">
          <el-input
            v-model="service.discountPrice"
            placeholder="请输入服务折扣价"
            type="number"
            oninput="value=value.replace(/\D|^0/g, '')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="confirm">
        <el-button type="primary" @click="onSubmitService('form-service')"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getPlaceUserList } from "@/api/order/order";

import {
  goAddParty,
  getOrderPrice,
  goAddPartyOrder,
  goEditPartyOrder,
  getUserCardList,
} from "@/api/party/party.js";
export default {
  props: {
    type: {
      type: Number,
      default: 1,
    },
    roomList: {
      type: Array,
      default: [],
    },
    row: {
      type: Object,
      default: null,
    },
    childRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userList: [],
      addVisible: false,
      title: "",
      btnTitle: "",
      timeToTime: [], //起止日期
      params: {
        merchantId: "",
        shopId: "",
        memberId: "", // 团主id
        timeToTime: [],
        startTime: "", // 团建开始时间
        endTime: "", // 团建结束时间
        peopleNum: "", // 团建人数
        corporateName: "", // 团建公司
        roomDiscount: 10, // 房间折扣
        mallDiscount: 10, // 商城折扣
        groupOrderDTO: {
          groupRoomOrderDTOList: [],
          otherServiceDTOList: [],
          cardId: "", // 卡券id
          subsidyAmount: 0, // 商家补贴
          orderPrice: "", //原价
          discountPrice: "", //折扣价
          payableAmount: "", //实际支付价格
        },
      },
      rules: {},
      loading: true,
      peopleList: [],
      addAddressVisible: false,
      addressTime: [], //起止日期
      address: {
        roomOrderId: "",
        id: "",
        startTime: "",
        endTime: "",
        addressTime: [],
        roomId: "",
        roomName: "",
        orderPrice: "",
        discountPrice: "",
      },

      rules: {
        memberId: [
          {
            required: true,
            message: "请输入用户名/手机号",
            trigger: "blur",
          },
        ],
        peopleNum: [
          {
            required: true,
            message: "请输入团建人数",
            trigger: "blur",
          },
        ],
        corporateName: [
          {
            required: true,
            message: "请输入团建公司",
            trigger: "blur",
          },
        ],
        timeToTime: [
          {
            required: true,
            message: "请选择团建时间",
            trigger: "change",
          },
        ],
        roomId: [
          {
            required: true,
            message: "请选择位置",
            trigger: "change",
          },
        ],
        addressTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        serviceName: [
          {
            required: true,
            message: "请输入服务名称",
            trigger: "blur",
          },
        ],
        orderPrice: [
          {
            required: true,
            message: "请输入服务原价",
            trigger: "blur",
          },
        ],
        discountPrice: [
          {
            required: true,
            message: "请输入服务折扣价",
            trigger: "blur",
          },
        ],
      },
      service: {
        groupOrderId: "",
        id: "",
        serviceName: "",
        orderPrice: "",
        discountPrice: "",
      },
      otherServiceVisible: false,
      cardList: [],

      isRoomAdd: 1, //位置 1:新增 0:编辑
      selectedAddressIndex: 0, // 选中的位置下标

      isServiceAdd: 1, //服务 1:新增 0:编辑
      selectedServiceIndex: 0, // 选中的其他服务下标
    };
  },
  watch: {
    timeToTime(val) {
      this.params.timeToTime = val;
      if (val) {
        if (val[0] != val[1]) {
          this.params.startTime = val[0];
          this.params.endTime = val[1];
        } else {
          this.params.startTime = "";
          this.params.endTime = "";
          this.$message.error({
            message: "团建开始时间和结束时间不能一样",
          });
        }
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
    },
    addressTime(val) {
      this.address.addressTime = val;
      if (val) {
        this.address.startTime = val[0];
        this.address.endTime = val[1];
      } else {
        this.address.startTime = "";
        this.address.endTime = "";
      }
    },
  },

  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.params.merchantId = JSON.parse(merchantShopId)[0];
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  methods: {
    formatNum() {
      let val = this.params.groupOrderDTO.subsidyAmount;
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.params.groupOrderDTO.subsidyAmount = temp;
    },

    //--通用---------------------------------------------------------------------------------
    //增加团单
    goAdd() {
      this.title = this.type == 1 ? "创建团建" : "增加团单";
      this.btnTitle = this.type == 1 ? "确认创建并发起收款" : "发起收款";
      let fatherRow = JSON.parse(JSON.stringify(this.row));
      let fatherChildRow = JSON.parse(JSON.stringify(this.childRow));
      switch (this.type) {
        case 1:
          this.title = "创建团建";
          this.addVisible = true;
          this.$refs["form-ref"].resetFields();
          break;
        case 2:
          this.params.memberId = fatherRow.memberId;
          this.params.startTime = fatherRow.startTime;
          this.params.endTime = fatherRow.endTime;
          this.params.roomDiscount = fatherRow.roomDiscount;
          this.params.mallDiscount = fatherRow.mallDiscount;
          this.params.groupOrderDTO.groupInfoId = fatherRow.id;
          this.params.groupOrderDTO.groupRoomOrderDTOList = [];
          this.params.groupOrderDTO.otherServiceDTOList = [];
          this.params.groupOrderDTO.cardId = ""; // 卡券id
          this.params.groupOrderDTO.subsidyAmount = 0; // 商家补贴
          this.title = "增加团单";
          this.getUserCard();
          this.addVisible = true;
          break;
        default:
          this.params.memberId = fatherRow.memberId;
          this.params.startTime = fatherRow.startTime;
          this.params.endTime = fatherRow.endTime;
          this.params.roomDiscount = fatherRow.roomDiscount;
          this.params.mallDiscount = fatherRow.mallDiscount;
          this.params.groupOrderDTO = {
            groupInfoId: fatherRow.id,
            groupRoomOrderDTOList: fatherChildRow.groupRoomOrderVoList || [],
            otherServiceDTOList: fatherChildRow.otherServiceVoList || [],
            cardId: fatherChildRow.cardId,
            subsidyAmount: fatherChildRow.subsidyAmount,
            orderPrice: fatherChildRow.orderPrice,
            discountPrice: fatherChildRow.discountPrice,
            payableAmount: fatherChildRow.payableAmount,
            groupOrderId: fatherChildRow.groupOrderId,
          };
          this.getUserCard();
          this.title = "编辑团单";
          this.calculateTotal()
          this.addVisible = true;

          break;
      }
    },
    // 1:添加位置 2:添加其他服务
    goAddOther(type) {
      if (type == 1) {
        this.isRoomAdd = 1;
        this.addressTime = [];
        this.address = {
          roomOrderId: "",
          id: "",
          startTime: "",
          endTime: "",
          addressTime: [],
          roomId: "",
          roomName: "",
          orderPrice: "",
          discountPrice: "",
        };
        this.addAddressVisible = true;
        this.$refs["form-address"].resetFields();
      } else {
        this.isServiceAdd = 1;
        this.service = {
          serviceName: "",
          orderPrice: "",
          discountPrice: "",
          groupOrderId: "",
          id: "",
        };
        this.otherServiceVisible = true;
        this.$refs["form-service"].resetFields();
      }
    },
    // 限制输入折扣
    goLimitValue() {
      if (this.params.roomDiscount && this.params.roomDiscount <= 0) {
        this.params.roomDiscount = 1;
      }
      if (this.params.roomDiscount > 10) {
        this.params.roomDiscount = 10;
      }
      if (this.params.mallDiscount && this.params.mallDiscount <= 0) {
        this.params.mallDiscount = 1;
      }
      if (this.params.mallDiscount > 10) {
        this.params.mallDiscount = 10;
      }
    },
    // 【监听】搜索值
    remoteMethod: _.debounce(function (kw) {
      let data = { kw };

      getPlaceUserList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.userList = res.data;
          } else {
            this.userList = [];
          }
        }
      });
    }, 500),
    //--位置相关---------------------------------------------------------------------------------
    // 更新房间价格
    goChangeRoomPrice() {
      if (
        this.params.groupOrderDTO.groupRoomOrderDTOList &&
        this.params.groupOrderDTO.groupRoomOrderDTOList.length
      ) {
        this.params.groupOrderDTO.groupRoomOrderDTOList.forEach((item) => {
          item.discountPrice = (
            (item.orderPrice * this.params.roomDiscount) /
            10
          ).toFixed(2);
        });
        this.calculateTotal();
      }
    },
    // 获取房间名
    getAddressRoomName() {
      let arr = this.roomList.filter(
        (item) => item.roomId == this.address.roomId
      );
      this.address.roomName = arr[0].roomName;
    },
    // 计算位置价格
    getOrderPrice() {
      let params = {
        roomId: this.address.roomId,
        startTime: this.address.startTime,
        endTime: this.address.endTime,
      };
      getOrderPrice(params).then((res) => {
        let orderPrice = res.data; // 初始价格
        let obj = {
          startTime: this.address.startTime,
          endTime: this.address.endTime,
          roomId: this.address.roomId,
          roomName: this.address.roomName,
          orderPrice: orderPrice,
          discountPrice: ((orderPrice * this.params.roomDiscount) / 10).toFixed(
            2
          ),
        };
        if (this.isRoomAdd == 1) {
          this.params.groupOrderDTO.groupRoomOrderDTOList.push(obj);
        } else {
          this.params.groupOrderDTO.groupRoomOrderDTOList[
            this.selectedAddressIndex
          ] = obj;
        }
        this.calculateTotal();

        this.addAddressVisible = false;
      });
    },
    // 新增位置提交
    onSubmitAddress(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.address.startTime == this.address.endTime) {
            this.$message.error({
              message: "位置订单开始时间和结束时间不能一样",
            });
          } else {
            this.getOrderPrice();
          }
        } else {
          return false;
        }
      });
    },
    // 编辑位置
    goEditAddress(item, index) {
      this.selectedAddressIndex = index;
      this.isRoomAdd = 0;
      this.address = JSON.parse(JSON.stringify(item));
      this.addressTime = [item.startTime, item.endTime];
      this.addAddressVisible = true;
    },
    // 删除位置
    goDeleteAddress(index) {
      this.$confirm("请确定是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.params.groupOrderDTO.groupRoomOrderDTOList.splice(index, 1);
          this.calculateTotal();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //--其他服务---------------------------------------------------------------------------------
    // 编辑服务
    goEditService(item, index) {
      this.isServiceAdd = 0;
      this.selectedServiceIndex = index;
      this.service = JSON.parse(JSON.stringify(item));
      this.otherServiceVisible = true;
    },
    // 删除服务
    goDeleteService(index) {
      this.$confirm("请确定是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.params.groupOrderDTO.otherServiceDTOList.splice(index, 1);
          this.calculateTotal();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 新增服务提交
    onSubmitService(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let service = JSON.parse(JSON.stringify(this.service));
          if (this.isServiceAdd == 1) {
            this.params.groupOrderDTO.otherServiceDTOList.push(service);
          } else {
            this.params.groupOrderDTO.otherServiceDTOList[
              this.selectedServiceIndex
            ] = service;
          }
          this.calculateTotal();

          this.otherServiceVisible = false;
        } else {
          return false;
        }
      });
    },
    //--计算价格-------------------------------------------------------------------------------
    // 修改折扣，保存位置，删除位置，保存服务，删除服务，修改商家补贴时调用
    // 计算总额
    calculateTotal() {
      let orderPrice = 0;
      let discountPrice = 0;
      let payableAmount = 0;
      let totalList = [];
      totalList = this.params.groupOrderDTO.groupRoomOrderDTOList.concat(
        this.params.groupOrderDTO.otherServiceDTOList || []
      );
      console.log(totalList);
      if (totalList.length) {
        totalList.forEach((i) => {
          orderPrice += Number(i.orderPrice);
          discountPrice += Number(i.discountPrice);
        });

        if (discountPrice - this.params.groupOrderDTO.subsidyAmount >= 0) {
          payableAmount = (
            discountPrice - this.params.groupOrderDTO.subsidyAmount
          ).toFixed(2);
        } else {
          payableAmount = 0;
        }
        // console.log("原价总额", orderPrice);
        // console.log("折扣总额", discountPrice);
        // console.log("实价总额", payableAmount);

        this.params.groupOrderDTO.orderPrice = orderPrice.toFixed(2);
        this.params.groupOrderDTO.discountPrice = discountPrice.toFixed(2);
        this.params.groupOrderDTO.payableAmount = payableAmount
      }
    },
    onSubmitParty(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.params.groupOrderDTO.groupRoomOrderDTOList.length == 0 &&
            this.params.groupOrderDTO.otherServiceDTOList.length == 0
          ) {
            this.$message({
              message: "请至少添加一个位置或一项其他服务",
              type: "error",
            });
          } else {
            switch (this.type) {
              case 1:
                this.goAddParty();
                break;
              case 2:
                this.goAddPartyOrder();
                break;
              case 3:
                this.goEditPartyOrder();
                break;
            }
          }
        } else {
          return false;
        }
      });
    },
    // 创建团建
    goAddParty() {
      goAddParty(this.params).then((res) => {
        if (res.isSuccess == "yes") {
          this.addVisible = false;
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.$emit("onSuccess");
        }
      });
    },
    // 新增团单
    goAddPartyOrder() {
      let params = this.params.groupOrderDTO;
      goAddPartyOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.addVisible = false;
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.$emit("onSuccess");
        }
      });
    },
    // 编辑团单
    goEditPartyOrder() {
      let params = this.params.groupOrderDTO;
      goEditPartyOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.addVisible = false;
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.$emit("onSuccess");
        }
      });
    },
    getUserCard() {
      if (
        this.params.memberId &&
        this.params.startTime &&
        this.params.endTime
      ) {
        this.getUserCardList();
      }
    },
    // 获取用户团建卡券
    getUserCardList() {
      let params = {
        memberId: this.params.memberId,
        orderStartTime: this.params.startTime,
        orderEndTime: this.params.endTime,
        shopId: this.params.shopId,
        memberCardId:this.params.groupOrderDTO.cardId
      };
      getUserCardList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.cardList = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-right: 10px;
}
.title {
  font-size: 18px;
  font-weight: 500;
  display: flex;
}
.line {
  display: flex;
}
.list {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .box {
    width: calc(100% - 40px);
    border-radius: 4px;
    border: 1px dotted #999;
    padding: 20px;
    margin-bottom: 20px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .item-line {
        display: flex;
        align-items: center;
      }
      .txt {
        margin-right: 20px;
        max-width: 230px;
        overflow: hidden; //溢出隐藏
        text-overflow: ellipsis; //超出显示省略号
        white-space: nowrap; //强制文本在一行内显示
      }
    }
    .item:last-child {
      margin-bottom: 0;
    }
  }
}
.confirm {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>