import request from '@/utils/request'
// 订单列表
export function getTableList(data) {
  return request('post', '/apm/order/orderList', data)
}

// 订单修改
export function getTableEdit(data) {
  return request('post', '/apm/order/update', data)
}

// 取消订单
export function getTableDel(data) {
  return request('post', '/apm/order/cancel', data)
}

// 房间列表
export function getIdroomList(data) {
  return request('post', '/apm/room/findRoom', data)
}

// 操作记录
export function getOperateList(data) {
  return request('post', '/apm/order/operateLog', data)
}

// 订单退款记录
export function getRefundList(data) {
  return request('post', '/apm/order/roomOrderDetailById', data)
}

// 订单退款
export function getRefundEdit(data) {
  return request('post', '/apm/order/refund', data)
}

// 离店
export function getLeave(data) {
  return request('post', '/apm/order/finish', data)
}

// 代客下单用户列表
export function getPlaceUserList(data) {
  return request('post', '/apm/order/user/list', data)
}

// 代客下单
export function getPlaceOrderAdd(data) {
  return request('post', '/apm/order/admin/create', data)
}
// 到店信息回显
export function orderArriveInfo(data) {
  return request('post', '/apm/order/arriveInfo', data)
}
// 开门
export function openDoor(data) {
  return request('post', '/apm/order/openRoom', data)
}
