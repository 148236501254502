<!--修改团建时间-->
<template>
  <div class="btn">
    <el-button type="text" size="medium" @click.stop="getRoomOrderList()"
      >包厢订单管理</el-button
    >
    <el-dialog title="包厢订单管理" :visible.sync="boxVisible" width="800rpx">
      <el-table
        :data="boxOrderList"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column label="位置" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.roomName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="预约时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.startTime }}～{{ scope.row.endTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="原价" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>¥{{ scope.row.orderPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ orderStatusObj[scope.row.orderStatus] }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orderStatus == 2 || scope.row.orderStatus == 3"
              type="text"
              size="medium"
              @click.stop="goClick(1, scope.row)"
              >修改订单</el-button
            >
            <el-button
              v-if="scope.row.orderStatus == 2"
              type="text"
              size="medium"
              @click.stop="goClick(2, scope.row)"
              >取消预定</el-button
            >
            <el-button
              v-if="scope.row.orderStatus == 3"
              type="text"
              size="medium"
              @click.stop="goClick(3, scope.row)"
              >离店</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="table-footer">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="total"
          :page-size="size"
          @size-change="onSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage"
          @current-change="onCurrentPage"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="修改订单" :visible.sync="changeVisible" width="600px">
      <el-form
        :model="params"
        :rules="rules"
        ref="form-ref"
        label-width="120px"
      >
        <!-- 起止时间 -->
        <el-form-item label="更换使用时间" prop="timeToTime">
          <el-date-picker
            v-model="timeToTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <!-- 位置名称 -->
        <el-form-item label="位置名称" prop="roomId">
          <el-select v-model="params.roomId" placeholder="请选择位置名称">
            <el-option
              v-for="item in roomList"
              :key="item.roomId"
              :label="item.roomName"
              :value="item.roomId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="onSubmit('form-ref')"
          >确定更换</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>
  <script>
import { orderStatusObj } from "@/db/objs.js";
import {
  getRoomOrderList,
  goChangeRoomAddress,
  goCancelRoomOrder,
  goFinishRoomOrder,
} from "@/api/party/party.js";
export default {
  props: {
    roomList: {
      type: Array,
      default: [],
    },
    row: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      boxVisible: false,
      orderStatusObj,
      boxOrderList: [],
      page: 1,
      size: 10,
      total: 0,
      currentPage: 1,
      changeVisible: false,
      timeToTime: [], //起止日期
      params: {
        id: "",
        startTime: "",
        endTime: "",
        timeToTime: [],
        roomId: "",
        roomOrderId: "",
      },
      rules: {
        timeToTime: [
          {
            required: true,
            message: "请选择更换时间",
            trigger: "change",
          },
        ],
        roomId: [
          {
            required: true,
            message: "请选择更换房间",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    timeToTime(val) {
      this.params.timeToTime = val;
      if (val) {
        this.params.startTime = val[0];
        this.params.endTime = val[1];
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
    },
  },
  methods: {
    // 获取包厢订单列表
    getRoomOrderList() {
      let params = {
        id: this.row.id,
        page: this.page,
        pageSize: this.size,
      };
      getRoomOrderList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.boxOrderList = res.data.list;
          this.total = res.data.total;
          this.boxVisible = true;
        }
      });
    },
    // 修改位置
    goChangeRoomAddress() {
      goChangeRoomAddress(this.params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.changeVisible = false;
          this.getRoomOrderList();
        }
      });
    },
    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(1, this.params);
          this.goChangeRoomAddress();
        } else {
          return false;
        }
      });
    },
    // 取消位置订单
    goCancelRoomOrder() {
      let params = {
        id: this.params.id,
      };
      goCancelRoomOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getRoomOrderList();
        }
      });
    },
    // 离店
    goFinishRoomOrder() {
      let params = {
        id: this.params.id,
      };
      goFinishRoomOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getRoomOrderList();
        }
      });
    },
    // 操作
    goClick(type, row) {
      console.log(1, row);
      switch (type) {
        case 1:
          this.timeToTime = [row.startTime, row.endTime];
          this.params = {
            startTime: row.startTime,
            endTime: row.endTime,
            timeToTime: [row.startTime, row.endTime],
            roomId: row.roomId,
            id: row.id,
            roomOrderId: row.roomOrderId,
          };
          this.changeVisible = true;
          break;
        case 2:
          this.params.id = row.id;
          this.$confirm("请确定是否取消预定?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.goCancelRoomOrder();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
          break;
        default:
          this.params.id = row.id;
          this.$confirm("请确定是否离店?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.goFinishRoomOrder();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
          break;
      }
    },
    // 【监听】表格条数点击
    onSizeChange(size) {
      this.size = size;
      this.reload();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.page = page;
      this.getRoomOrderList();
    },
    // 表格重载
    reload() {
      this.page = 1;
      this.currentPage = 1;
      this.getRoomOrderList();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-right: 10px;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>