import request from "@/utils/request";

// 新增团建
export function goAddParty(data) {
  return request("post", "/apm/group/info/create", data);
}

// 新增团单
export function goAddPartyOrder(data) {
  return request("post", "/apm/group/info/create/order", data);
}

// 编辑团单
export function goEditPartyOrder(data) {
  return request("post", "/apm/group/info/updateOrderById", data);
}

// 修改团建时间
export function goChangePartyTime(data) {
  return request("post", "/apm/group/info/updateById", data);
}

// 查询团建操作记录
export function getPartyRecordList(data) {
  return request("post", "/apm/group/info/groupInfoOptRecord", data);
}

// 取消团单
export function goCancelParty(data) {
  return request("post", "/apm/group/info/cancel", data);
}
// 团单操作记录
export function getPartyOrderRecordList(data) {
  return request("post", "/apm/group/info/groupOrderOptRecord", data);
}
// 获取订单价格
export function getOrderPrice(data) {
  return request("post", "/apm/order/getOrderPrice", data);
}

// 团建列表
export function getPartyList(data) {
  return request("post", "/apm/group/info/list", data);
}

// 删除团建列表
export function goDeleteParty(data) {
  return request("post", "/apm/group/info/removeById", data);
}


// 包厢订单管理
export function getRoomOrderList(data) {
  return request("post", "/apm/group/info/roomOrderList", data);
}

// 查询用户团建卡券
export function getUserCardList(data) {
  return request("post", "/apm/group/info/memberGroupCardList", data);
}


// 重发收款
export function goResurrecting(data) {
  return request("post", "/apm/group/info/resurrecting", data);
}

// 修改位置订单
export function goChangeRoomAddress(data) {
  return request("post", "/apm/group/info/updateRoomOrder", data);
}

// 取消位置订单
export function goCancelRoomOrder(data) {
  return request("post", "/apm/group/info/cancelRoomOrder", data);
}

// 离店
export function goFinishRoomOrder(data) {
  return request("post", "/apm/group/info/finishRoomOrder", data);
}

// 团建结算
export function goSettlementOrder(data) {
  return request("post", "/apm/group/info/settlement", data);
}

// 团单退款详情
export function getGroupOrderById(data) {
  return request("post", "/apm/group/info/getGroupOrderById", data);
}

// 团单续单退款详情
export function getRoomOrderDetailById(data) {
  return request("post", "/apm/group/info/getRoomOrderDetailById", data);
}

// 团单退款
export function goRefundGroupOrder(data) {
  return request("post", "/apm/group/info/refund", data);
}