<!--重发收款按钮-->
<template>
  <div class="btn">
    <el-button type="text" size="medium" @click.stop="getGroupOrderById()"
      >退款</el-button
    >
    <el-dialog
      title="订单退款"
      :visible.sync="refundShow"
      :close-on-click-modal="false"
      append-to-body
      width="700px"
    >
      <div v-if="refundList.length > 0">
        <el-form
          v-for="item in refundList"
          :key="item.orderId"
          :model="item"
          :rules="rules"
          :ref="'form'"
          inline
        >
          <section class="form-main">
            <el-descriptions title="订单支付详情" :column="2">
              <el-descriptions-item label="下单用户"
                >{{ item.nickName }} | {{ item.mobile }}</el-descriptions-item
              >
              <el-descriptions-item label="支付时间">{{
                item.paymentTime
              }}</el-descriptions-item>
              <el-descriptions-item label="支付金额"
                >¥{{ item.payableAmount }}</el-descriptions-item
              >
              <el-descriptions-item label="支付方式">{{
                item.paymentTypeName
              }}</el-descriptions-item>
              <el-descriptions-item label="已退金额">{{
                item.refundInfo || "-"
              }}</el-descriptions-item>
            </el-descriptions>
            <div class="inputBox">
              <el-form-item
                v-if="item.canRefundAmount != 0"
                prop="wxRefundAmount"
                :label="'微信退款(可退款金额：¥' + item.canRefundAmount + ')'"
              >
                <el-input
                  v-model.trim="item.wxRefundAmount"
                  autocomplete="off"
                  maxlength="8"
                  placeholder="请输入微信退款金额"
                  @input="
                    (v) => (item.wxRefundAmount = v.replace(/[^\d.]/g, ''))
                  "
                >
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item
                v-if="item.canRefundBalanceAmount != 0"
                prop="bjRefundAmount"
                :label="
                  '本金退款(可退款金额：¥' + item.canRefundBalanceAmount + ')'
                "
                @input="(v) => (aaa = v.replace(/[^\d.]/g, ''))"
              >
                <el-input
                  v-model.trim="item.bjRefundAmount"
                  autocomplete="off"
                  maxlength="8"
                  placeholder="请输入本金退款金额"
                  @input="
                    (v) => (item.bjRefundAmount = v.replace(/[^\d.]/g, ''))
                  "
                >
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </div>
          </section>
          <section class="form-footer">
            <el-button
              type="info"
              plain
              disabled
              v-if="
                item.canRefundAmount == 0 && item.canRefundBalanceAmount == 0
              "
              >已退款</el-button
            >
            <el-button
              v-else
              type="primary"
              :loading="loading"
              @click="onSubmit('form', 1)"
              >确认退款</el-button
            >
          </section>
        </el-form>
      </div>
      <div v-if="refundRenewalList.length > 0">
        <el-form
          class="renewal"
          v-for="(item, index) in refundRenewalList"
          :key="item.orderId"
          :model="item"
          :rules="rules"
          :ref="'form' + index"
          inline
        >
          <section class="form-main">
            <el-descriptions title="续单支付详情" :column="2">
              <el-descriptions-item label="下单用户"
                >{{ item.nickName }} | {{ item.mobile }}</el-descriptions-item
              >
              <el-descriptions-item label="支付时间">{{
                item.paymentTime
              }}</el-descriptions-item>
              <el-descriptions-item label="支付金额"
                >¥{{ item.paymentAmount }}</el-descriptions-item
              >
              <el-descriptions-item label="支付方式">{{
                item.paymentTypeStr
              }}</el-descriptions-item>
              <el-descriptions-item label="已退金额">{{
                item.refundInfo || "-"
              }}</el-descriptions-item>
            </el-descriptions>
            <div class="inputBox">
              <el-form-item
                v-if="item.canRefundAmount != 0"
                prop="wxRefundAmount"
                :label="'微信退款(可退款金额：¥' + item.canRefundAmount + ')'"
              >
                <el-input
                  v-model.trim="item.wxRefundAmount"
                  autocomplete="off"
                  maxlength="8"
                  placeholder="请输入微信退款金额"
                  @input="
                    (v) => (item.wxRefundAmount = v.replace(/[^\d.]/g, ''))
                  "
                >
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item
                v-if="item.canRefundBalanceAmount != 0"
                prop="bjRefundAmount"
                :label="
                  '本金退款(可退款金额：¥' + item.canRefundBalanceAmount + ')'
                "
                @input="(v) => (aaa = v.replace(/[^\d.]/g, ''))"
              >
                <el-input
                  v-model.trim="item.bjRefundAmount"
                  autocomplete="off"
                  maxlength="8"
                  placeholder="请输入本金退款金额"
                  @input="
                    (v) => (item.bjRefundAmount = v.replace(/[^\d.]/g, ''))
                  "
                >
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </div>
          </section>
          <section class="form-footer">
            <el-button
              type="info"
              plain
              disabled
              v-if="
                item.canRefundAmount == 0 && item.canRefundBalanceAmount == 0
              "
              >已退款</el-button
            >
            <el-button
              v-else
              type="primary"
              :loading="item.loading"
              @click="onSubmit('form' + index, 2, index)"
              >确认退款</el-button
            >
          </section>
        </el-form>
      </div>
      <section v-if="refundList.length == 0 && refundRenewalList.length == 0">
        <el-empty description="暂无订单信息"></el-empty>
      </section>
    </el-dialog>
  </div>
</template>
  <script>
import {
  getGroupOrderById,
  getRoomOrderDetailById,
  goRefundGroupOrder,
} from "@/api/party/party.js";
import { getRefundEdit } from "@/api/order/order";

export default {
  props: {
    childRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      refundShow: false,
      refundList: [],
      refundRenewalList: [],
      rules: {
        wxRefundAmount: [
          {
            required: true,
            message: "请输入微信退款金额",
            trigger: "blur",
          },
        ],
        bjRefundAmount: [
          {
            required: true,
            message: "请输入本金退款金额",
            trigger: "blur",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    // 判断是否大于可退款金额
    goCheckRefundMoney(index, type) {
      let item = type == 1 ? this.refundList[0] : this.refundRenewalList[index];
      if (item.wxRefundAmount > item.canRefundAmount) {
        return 1;
      } else if (item.bjRefundAmount > item.canRefundBalanceAmount) {
        return 2;
      } else {
        return 3;
      }
    },
    // 团单退款款
    goRefundGroupOrder() {
      let params = {
        groupOrderId: this.childRow.groupOrderId,
        refundAmount: this.refundList[0].wxRefundAmount,
        balanceRefundAmount: this.refundList[0].bjRefundAmount,
      };
      goRefundGroupOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.$emit("onSuccess");
        }
        this.loading = false;
      });
    },
    // 【监听】表单提交
    onSubmit(formName, type, index) {
      console.log(type);
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          let errType = this.goCheckRefundMoney(index, type);
          switch (errType) {
            case 1:
              this.$message({
                message: "微信退款金额超出最大可退款金额",
                type: "error",
              });
              break;
            case 2:
              this.$message({
                message: "本金退款金额超出最大可退款金额",
                type: "error",
              });
              break;
            case 3:
              //   1 团单订单 2续单订单
              if (type == 1) {
                this.loading = true;
                this.goRefundGroupOrder();
              } else {
                this.refundRenewalList[index].loading = true;
                let data = {
                  orderId: this.refundRenewalList[index].orderId,
                  refundAmount: this.refundRenewalList[index].wxRefundAmount,
                  balanceRefundAmount:
                    this.refundRenewalList[index].bjRefundAmount,
                };
                this.getRefundEdit(data, index);
              }
              break;
          }
        } else {
          return false;
        }
      });
    },
    // 续单退款
    getRefundEdit(data, index) {
      getRefundEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.refundRenewalList[index].loading = false;
          this.$emit("onSuccess");
        }
      });
    },
    // 查询团单退款详情
    getGroupOrderById() {
      let params = {
        groupOrderId: this.childRow.groupOrderId,
      };
      getGroupOrderById(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.refundList = [res.data];
          this.getRoomOrderDetailById();
          this.refundShow = true;
        }
      });
    },
    // 查询团单续单退款详情
    getRoomOrderDetailById() {
      let params = {
        groupOrderId: this.childRow.groupOrderId,
      };
      getRoomOrderDetailById(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.refundRenewalList = res.data;
          this.refundRenewalList.forEach((i) => {
            i.loading = false;
          });
        }
      });
    },
  },
};
</script>
    <style lang="scss" scoped>
.btn {
  margin-right: 10px;
}
.renewal {
  margin-top: 30px;
}
</style>