<!--操作记录-->
<template>
  <div class="btn">
    <el-button type="text" size="medium" @click.stop="getPartyOrderRecordList()"
      >操作记录</el-button
    >
    <el-dialog title="操作记录" :visible.sync="recordVisible" width="800px">
      <el-table
        :data="recordList"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column label="操作时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.addDt }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.userName }}｜{{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作类型" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.operateTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作内容" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.content||'-' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-footer">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="total"
          :page-size="size"
          @size-change="onSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="currentPage"
          @current-change="onCurrentPage"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getPartyOrderRecordList } from "@/api/party/party.js";
export default {
  props: {
    childRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      recordVisible: false,
      recordList: [],
      page: 1,
      size: 10,
      total: 0,
      currentPage: 1,
    };
  },
  methods: {
    getPartyOrderRecordList() {
      let params = {
        page: this.page,
        pageSize: this.size,
        groupOrderId: this.childRow.groupOrderId,
      };
      getPartyOrderRecordList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.recordList = res.data.list;
          this.total = res.data.total;
          this.recordVisible = true;
        }
      });
    },
    // 【监听】表格条数点击
    onSizeChange(size) {
      this.size = size;
      this.reload();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.page = page;
      this.getPartyOrderRecordList()
    },
    // 表格重载
    reload() {
      this.page = 1;
      this.currentPage = 1;
      this.getPartyOrderRecordList()
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-right: 10px;
}
.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>