<!--结算按钮-->
<template>
  <div class="btn">
    <el-button type="text" size="medium" @click.stop="goSettle()"
      >结算</el-button
    >
  </div>
</template>
<script>
import { goSettlementOrder } from "@/api/party/party.js";
export default {
  props: {
    row: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 团建结算
    goSettle() {
      let params = {
        id: this.row.id,
      };
      goSettlementOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$emit("onSuccess");

        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-right: 10px;
}
</style>