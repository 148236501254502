<!--删除按钮-->
<template>
  <div class="btn">
    <el-button type="text" size="medium" @click.stop="goDelete()"
      >删除</el-button
    >
  </div>
</template>
<script>
import {goDeleteParty} from '@/api/party/party.js'
export default {
  props: {
    row: {
      type: Object,
      default: {},
    },
  },
  methods: {
    goDeleteParty(){
      let params={
        id:this.row.id
      }
      goDeleteParty(params).then(res=>{
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.$emit("onSuccess");
        }
      })
    },
    goDelete() {
      this.$confirm("请确定是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.goDeleteParty()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn{
    margin-right:10px;
}
</style>