<!--修改团建时间-->
<template>
  <div class="btn">
    <el-button type="text" size="medium" @click.stop="changeVisible = true"
      >修改团建时间</el-button
    >
    <el-dialog title="提示" :visible.sync="changeVisible" width="600px">
      <el-form
        :model="params"
        :rules="rules"
        ref="form-ref"
        label-width="120px"
      >
        <!-- 起止时间 -->
        <el-form-item label="更换使用时间" prop="timeToTime">
          <el-date-picker
            v-model="timeToTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="getPartyRecordList()">修改记录</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="修改记录" :visible.sync="recordVisible" width="1000px">
      <el-table
        :data="recordList"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column label="操作时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.addDt }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.userName }}｜{{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="团建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.content }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-footer" v-if="recordForm.total > 0">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="recordForm.total"
          :page-size="recordForm.pageSize"
          @size-change="onSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="recordForm.currentPage"
          @current-change="onCurrentPage"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { goChangePartyTime, getPartyRecordList } from "@/api/party/party.js";
export default {
  props: {
    row: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      changeVisible: false,
      recordVisible: false,
      timeToTime: [this.row.startTime, this.row.endTime],
      params: {
        startTime: this.row.startTime,
        endTime: this.row.endTime,
        timeToTime: [this.row.startTime, this.row.endTime],
      },
      rules: {
        timeToTime: [
          {
            required: true,
            message: "请选择更换时间",
            trigger: "change",
          },
        ],
      },
      recordList: [],
      recordForm: {
        page: 1,
        pageSize: 10,
        total: 0,
        currentPage: 1,
        id: "",
      },
    };
  },
  watch: {
    timeToTime(val) {
      this.params.timeToTime = val;
      if (val) {
        if (val[0] != val[1]) {
          this.params.startTime = val[0];
          this.params.endTime = val[1];
        } else {
          this.params.startTime = "";
          this.params.endTime = "";
          this.$message.error({
            message: "团建开始时间和结束时间不能一样",
          });
        }
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
    },
  },
  methods: {
    // 查询团建操作记录
    getPartyRecordList() {
      this.recordForm.id = this.row.id;
      getPartyRecordList(this.recordForm).then((res) => {
        if (res.isSuccess == "yes") {
          this.recordList = res.data.list;
          this.recordForm.total = res.data.total;
          this.recordVisible = true;
        }
      });
    },
    // 修改团建时间
    goChangePartyTime() {
      let params = {
        id: this.row.id,
        startTime: this.params.startTime,
        endTime: this.params.endTime,
      };
      goChangePartyTime(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.changeVisible = false;
          this.$emit("onSuccess");
        }
      });
    },
    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.goChangePartyTime();
        } else {
          return false;
        }
      });
    },
    // 【监听】表格条数点击
    onSizeChange(size) {
      this.recordForm.pageSize = size;
      this.reload();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.recordForm.page = page;
      this.getPartyRecordList();
    },

    // 表格重载
    reload() {
      this.recordForm.page = 1;
      this.recordForm.currentPage = 1;
      this.getPartyRecordList();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  margin-right: 10px;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>